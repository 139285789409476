import React from 'react';
import { graphql } from 'gatsby';
import {useI18next} from "../../plugins/gatsby-plugin-ap-i18next/src/useI18next";

import {
    layout,
    background,
    introBox,
    secretList,
    list,
} from './dessert-page.module.scss';

import { IPage } from '../models/page.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IProductPreview } from '../models/product-preview.model';
import { ISitePageContext } from '../models/site-page.model';
import {ImageDataLike} from "../models/image-data-like.model";

import {getNodes} from "../utils/get-nodes";
import {getSecrets} from "../utils/get-secrets";

import MainLayout from '../layouts/main-layout';
import BackgroundRepeat from '../layouts/background-repeat';
import PageIntro from "../components/organisms/page-intro";
import ProductList from "../components/organisms/product-list";
import SecretList from "../components/organisms/secret-list";



interface IDessertPageProps {
    readonly data: {
        page: IPage | null;
        allStrapiOldRecipe: IQueryAllResult<IProductPreview>;
        allSecretImg: IQueryAllResult<ImageDataLike>;
    };
    readonly pageContext: ISitePageContext;
}

const DessertPage: React.FC<IDessertPageProps> = ({ data, pageContext }) => {
    const { t } = useI18next();
    const { page, allStrapiOldRecipe, allSecretImg } = data;
    const { site } = pageContext;
    const secretImages = getNodes(allSecretImg);
    const { introContent } = page || {};
    const products = getNodes(allStrapiOldRecipe);
    const secrets = getSecrets(secretImages, 'flavored.secret.item.title', t, "dessert");

    return (
        <MainLayout className={layout} page={page} siteKey={site}>
            <BackgroundRepeat className={background}>
                <div className={introBox}>
                    <PageIntro title={t("layout.page.intro.title")} subTitle={t("linksMain.square.secondLine.2")} description={introContent} showBackground={false} />
                    <SecretList
                        className={secretList}
                        secrets={secrets}
                        isGoldColor={true}
                    />
                </div>
                <ProductList recipes={products} className={list} showAbsoluteImage={false} />
            </BackgroundRepeat>
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
        
        allSecretImg: allFile(
            filter: {
                relativePath: {
                    in: [
                        "flavored-secret-05.png"
                        "flavored-secret-08.png"
                        "flavored-secret-13.png"
                        "flavored-secret-14.png"
                    ]
                }
            }
            sort: { fields: relativePath, order: ASC }
        ) {
            edges {
                node {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
            }
        }
        
        allStrapiOldRecipe(
            filter: {
                locale: { eq: $language }
                showIn: { eq: $site }
                isDummyContent: { eq: false }
            }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...oldRecipeFields
                }
            }
        }
    }
`;

export default DessertPage;
